<template>
    <div id="log_manage">
        <jy-query ref="form" :model="form">
            <jy-query-item label="线路:" prop="routeId" label-width="70px">
                <!-- <div @click="xlselectInstitutions">
                    <el-input placeholder="请选择" v-model="form.routeName"></el-input>
                </div> -->
                <el-select v-model="form.routeId" placeholder="请选择线路">
                    <el-option v-for="item in routeOptions" :key="item.routeId" :label="item.name" :value="item.routeId">
                    </el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item prop="type" label="信息类型:">
                <el-select v-model="form.type" placeholder="请选择">
                    <el-option v-for="item in inforOptions" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item prop="driverName" label="下发对象:">
                <el-input placeholder="请输入" v-model="form.driverName"></el-input>
            </jy-query-item>
            <jy-query-item prop="driverPhoneNo" label="联系方式:">
                <el-input placeholder="请输入" v-model="form.driverPhoneNo"></el-input>
            </jy-query-item>
            <jy-query-item prop="channel" label="下发渠道:">
                <el-select v-model="form.channel" placeholder="请选择">
                    <el-option v-for="item in channelOptions" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item prop="status" label="确认状态:">
                <el-select v-model="form.status" placeholder="请选择">
                    <el-option v-for="item in stateOptions" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="查询日期:" prop="time" :span="1.5">
                <el-date-picker v-model="form.time" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" :picker-options="pickerOptions" :unlink-panels="true"></el-date-picker>
            </jy-query-item>
            <template slot="rightCol">
                <el-button type="primary" @click="oncheck">查询</el-button>
                <el-button type="primary" @click="resetForm('form')">重置</el-button>
                <el-row>
                    <!-- <el-button type="primary">导出</el-button> -->
                </el-row>
            </template>
        </jy-query>
        <jy-table :data="dataList">
            <jy-table-column type="index" label="序号" width="60"></jy-table-column>
            <jy-table-column prop="routeName" label="线路"></jy-table-column>
            <jy-table-column prop="orgName" label="所属机构"></jy-table-column>
            <jy-table-column prop="typeLabel" label="信息类型"></jy-table-column>
            <jy-table-column prop="driverName" label="下发对象"></jy-table-column>
            <jy-table-column prop="driverPhoneNo" label="联系方式"></jy-table-column>
            <jy-table-column prop="content" label="下发内容"></jy-table-column>
            <jy-table-column prop="channelLabel" label="下发渠道"></jy-table-column>
            <jy-table-column prop="createTime" label="下发时间"></jy-table-column>
            <jy-table-column prop="statusLabel" label="确认状态"></jy-table-column>
        </jy-table>
        <jy-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageIndex" :page-size="pageSize" :total="total"></jy-pagination>
        <xlinstitutions-tree ref="xlinstitutions" @addInstitutions="xladdInstitutions" url="/waybillOperate/getTree" title="选择线路" :defaultProps="props"></xlinstitutions-tree>
    </div>
</template>
<script>
import xlinstitutionsTree from '@/components/pages/admin/common/dialog-tree/institutions_tree'
export default {
    data() {
        return {
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now() - 8.64e7
                },
            },

            props: {
                children: 'children',
                label: 'text',
                value: 'id',
            },
            form: {
                routeName: '',
                routeId: '',
                type: '',
                driverName: '',
                driverPhoneNo: '',
                channel: '',
                status: '',
                time: [],
                startTime: '',
                endTime: '',
            },
            pageSize: 10,
            total: 0,
            pageIndex: 1,
            // 列表列数据
            list: [],

            dataList: [],

            inforOptions: [
                {
                    value: '',
                    label: '全部',
                },
                {
                    value: '0',
                    label: '发车提醒',
                },
                {
                    value: '1',
                    label: '班次调整',
                },
            ],
            channelOptions: [
                {
                    value: '',
                    label: '全部',
                },
                {
                    value: '0',
                    label: '公众号',
                },
                {
                    value: '1',
                    label: '设备',
                },
                {
                    value: '2',
                    label: '短信',
                },
            ],
            stateOptions: [
                {
                    value: '',
                    label: '全部',
                },
                {
                    value: '0',
                    label: '未确认',
                },
                {
                    value: '1',
                    label: '已确认',
                },
            ],
            routeOptions:[],
        }
    },
    components: {
        xlinstitutionsTree,
    },
    created() {
        this.getList()
    },
    activated() {
        this.getRouteList()
    },
    methods: {
        getRouteList(){
            let url = '/baseinforoute/queryCurrentAuthorityRoute'
            this.$http.post(url).then(({detail})=>{
                this.routeOptions = detail
            })
        },
        getList() {
            let option = {
                ...this.form,
                pageIndex: this.pageIndex,
                pageSize: this.pageSize,
            }
            if (option.time != null && option.time.length) {
                option.startTime = option.time[0] + ' 00:00:00'
                option.endTime = option.time[1] + ' 23:59:59'
            } else {
                option.startTime = ''
                option.endTime = ''
            }
            let url = '/NotifyLog/list'
            // console.log(option)
            this.$http.post(url, option).then(res => {
                this.dataList = res.detail.list
                this.total = res.detail.total
            })
        },
        // 查询表格
        oncheck() {
            this.pageIndex = 1
            this.getList()
            console.log('更新')
        },
        // 重置
        resetForm(formName) {
            this.$refs[formName].resetFields()
            this.form.routeId = ''
            this.oncheck()
        },
        // 选择线路树
        xlselectInstitutions() {
            this.$refs.xlinstitutions.init()
        },
        // 上级线路机构查询
        xladdInstitutions(data) {
            // console.log(data)
            this.form.routeId = data.id
            this.form.routeName = data.text
        },
        // 分页
        handleSizeChange(val) {
            this.pageSize = val
            this.getList()
        },
        handleCurrentChange(val) {
            this.pageIndex = val
            this.getList()
        },
    },
}
</script>
